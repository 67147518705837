.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #ab001c;
}
.button:active {
  background-color: #780014;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  margin: 10px 0;
}
div.sub1 > .item {
  margin-right: 20px;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  text-decoration: none;
  font-size: 12px;
  line-height: 1.33333333;
  color: #000;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  color: #c40020;
}
div.sub1 > .item > .menu.path {
  font-weight: 500;
  color: #000;
}
div.sub2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.sub2 > .item {
  width: 25%;
  box-sizing: border-box;
  border-left: 10px solid;
  position: relative;
}
div.sub2 > .item.c1 {
  border-color: #c40020;
}
div.sub2 > .item.c2 {
  border-color: #839caf;
}
div.sub2 > .item.c3 {
  border-color: #847f73;
}
div.sub2 > .item.c4 {
  border-color: #710030;
}
div.sub2 > .item:hover > .menu,
div.sub2 > .item:focus > .menu {
  background-color: #dcdcd8;
}
div.sub2 > .item > .menu {
  height: 100%;
  padding: 7px 10px;
  color: #000;
  text-decoration: none;
  font-size: 15px;
  line-height: 1.2;
  box-sizing: border-box;
  min-height: 50px;
}
div.sub2 > .item > .menu.path {
  background-color: #dcdcd8;
}
div.sub3 {
  position: absolute;
  top: 100%;
  left: -10px;
  min-width: calc(100% + 10px);
  background-color: #dcdcd8;
  border-top: 1px solid #fff;
  flex-direction: column;
  align-items: flex-start;
  display: none;
}
div.sub3 > .item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #fff;
  box-sizing: border-box;
  padding-left: 20px;
}
div.sub3 > .item.init {
  border: none;
}
div.sub3 > .item:hover > .menu,
div.sub3 > .item:focus > .menu {
  color: #c40020;
  background-image: url(/images/chevron-right-red.svg);
}
div.sub3 > .item > .menu {
  white-space: nowrap;
  color: #000;
  font-size: 16px;
  line-height: 1.375;
  text-decoration: none;
  padding: 7px 40px 7px 16px;
  background-size: 8px 12px;
  background-position: 0 12px;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-black.svg);
}
div.sub3 > .item > .menu.path {
  color: #c40020;
  background-image: url(/images/chevron-right-red.svg);
}
div.sub3 > .item > .menu .cb-navigation-subtext {
  display: none;
}
div.sub2 > .item:hover div.sub3 {
  display: flex;
}
div.sub4 {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 16px;
}
div.sub4 > .item {
  margin: 2px 0;
}
div.sub4 > .item.init {
  margin-top: 0;
}
div.sub4 > .item.exit {
  margin-bottom: 10px;
}
div.sub4 > .item > .menu {
  font-size: 16px;
  line-height: 1.375;
  padding-left: 12px;
  padding-right: 20px;
  white-space: nowrap;
  background-size: 6px 9px;
  background-position: 0 6px;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-black.svg);
  text-decoration: none;
  color: #000;
}
div.sub4 > .item > .menu:hover,
div.sub4 > .item > .menu:focus,
div.sub4 > .item > .menu.path {
  color: #c40020;
  background-image: url(/images/chevron-right-red.svg);
}
div.sub4 > .item > .menu .cb-navigation-subtext {
  display: none;
}
.content--1,
.content--3 {
  width: 65.6504065%;
}
.content--2 {
  float: right;
  width: 31.30081301%;
}
.content {
  margin-top: 30px;
}
.content--1 .area .pure:not(.fold),
.content--3 .area .unit:not(.fold) {
  padding-bottom: 34px;
}
.content--1 .area .seam:not(.fold),
.content--1 .area .flat:not(.fold),
.content--2 .area .seam:not(.fold),
.content--2 .area .flat:not(.fold) {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .edge:not(.fold),
.content--2 .area .edge:not(.fold) {
  margin-bottom: -14px;
}
.unit.fold + .unit.fold {
  margin-top: -21px;
}
.content--1 .area .fold.pure div.less,
.content--3 .area .fold.pure div.less,
.content--1 .area .fold.seam div.less,
.content--3 .area .fold.seam div.less {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .fold.pure div.more,
.content--3 .area .fold.pure div.more,
.content--1 .area .fold.seam div.more,
.content--3 .area .fold.seam div.more {
  padding-bottom: 8px;
}
.content--1 .area .fold.flat div.less,
.content--3 .area .fold.flat div.less,
.content--1 .area .fold.edge div.less,
.content--3 .area .fold.edge div.less {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .fold.flat div.more,
.content--3 .area .fold.flat div.more,
.content--1 .area .fold.edge div.more,
.content--3 .area .fold.edge div.more {
  padding-bottom: 8px;
}
.content--1 .area,
.content--3 .area {
  width: 100%;
}
.content--1 .area > .unit,
.content--3 .area > .unit {
  margin-right: 2.21893491%;
  margin-left: 2.21893491%;
  width: 95.56213018%;
}
.content--1 .area .cb-album .body,
.content--3 .area .cb-album .body,
.content--1 .area .head,
.content--3 .area .head,
.content--1 .area .foot,
.content--3 .area .foot,
.content--1 .area .part,
.content--3 .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--1 .area > .slim,
.content--3 .area > .slim {
  width: 45.56213018%;
}
.content--1 .area > .slim .head,
.content--3 .area > .slim .head,
.content--1 .area > .slim .foot,
.content--3 .area > .slim .foot,
.content--1 .area > .slim .part,
.content--3 .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--1 .area > .slim.cb-album .body,
.content--3 .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--1 .area > .slim .tiny,
.content--3 .area > .slim .tiny {
  width: 100%;
}
.content--1 .area,
.content--3 .area {
  width: 104.64396285%;
  margin-left: -2.32198142%;
}
.content--1 .area .unit.pure.wide .body,
.content--3 .area .unit.pure.wide .body {
  width: 104.64396285%;
  margin-left: -2.32198142%;
}
.content--1 .area .unit.pure.wide .part,
.content--3 .area .unit.pure.wide .part {
  margin-left: 2.21893491%;
  margin-right: 2.21893491%;
  width: 95.56213018%;
}
.content--1 .area .unit.pure.wide .part.tiny,
.content--3 .area .unit.pure.wide .part.tiny {
  width: 45.56213018%;
}
.content--1 .area .fold,
.content--3 .area .fold {
  width: 95.56213018%;
}
.content--1 .area .fold .body,
.content--3 .area .fold .body {
  width: 104.64396285%;
  margin-left: -2.32198142%;
}
.content--1 .area .fold .part,
.content--3 .area .fold .part {
  margin-left: 2.21893491%;
  margin-right: 2.21893491%;
  width: 95.56213018% !important;
}
.content--1 .area .fold .part.tiny,
.content--3 .area .fold .part.tiny {
  width: 45.56213018% !important;
}
/*# sourceMappingURL=./screen-large.css.map */